import { useLayoutEffect, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import './competencies.scss';
import { Helmet } from "react-helmet-async";
import { Header } from "./header";
import { BottomHooks } from "./bottomHooks";
import skills from './skills.json';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(ScrollTrigger, TextPlugin);

export function Competencies() {

  const { t } = useTranslation();
  const competencesScrollIcon = useRef(null);
  const heroSectionRef = useRef(null);
  const competenciesRef = useRef(null);
  const renderDots = (level) => '•'.repeat(level);

  // Referencje do sekcji "coding"
  const codingRef = useRef(null);
  const codingDescRef = useRef(null);
  const languagesRef = useRef(null);
  const frameworksRef = useRef(null);
  const toolsRef = useRef(null);
  const cmsRef = useRef(null);

  // Referencje do innych sekcji
  const webDesignRef = useRef(null);
  const webDesignDescRef = useRef(null);
  const webDesignSkillsRef = useRef(null);

  const workFlowRef = useRef(null);
  const workFlowDescRef = useRef(null);
  const workFlowSkillsRef = useRef(null);

  useEffect(() => {
    document.body.classList.add('competencies');
    return () => {
      document.body.classList.remove('competencies');
    };
  }, []);

  useLayoutEffect(() => {
    // Definicja animacji GSAP
    const animation = gsap.to('#title', {
      duration: 2,
      text: t('competenciesPageHeroSectionTitle'),
      delay: 1,
    });

    // Czyszczenie (cleanup) animacji przy demontażu komponentu
    return () => {
      animation.kill();
    };
  }, [t]);

  useLayoutEffect(() => {
    ScrollTrigger.refresh();
    const tlDesc = gsap.timeline({
      scrollTrigger: {
        trigger: competenciesRef.current,
        pin: true,
        pinSpacing: true,
        scrub: 1,
        start: "top top",
        end: "+=2000%",
      }
    });

    tlDesc.to(codingRef.current, {
      opacity: 1,
      scale: 1,
      zIndex: 2,
      duration: 10,
      ease: "power3.out"
    }, "-=0.3", {scrub: 0.1});
    tlDesc.to(codingDescRef.current, {
      opacity: 1,
      scale: 1,
      transform: "translate(-50%, -50%)",
      zIndex: 3,
      duration: 10,
      ease: "power3.out"
    }, "-=0.2");
    tlDesc.to(codingDescRef.current, {
      opacity: 0,
      scale: 0,
      transform: "translate(-50%, -50%)",
      zIndex: 1,
      duration: 10,
      ease: "power3.out"
    }, "-=0.1");

      tlDesc.to(languagesRef.current, {
        opacity: 1,
        scale: 1,
        transform: "translate(-50%, -50%)",
        zIndex: 3,
        duration: 10,
        ease: "power3.out"
      }, "-=0.2");
      tlDesc.to(languagesRef.current, {
        opacity: 0,
        scale: 0,
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        duration: 10,
        ease: "power3.out"
      }, "-=0.1");

      tlDesc.to(frameworksRef.current, {
        opacity: 1,
        scale: 1,
        transform: "translate(-50%, -50%)",
        zIndex: 3,
        duration: 10,
        ease: "power3.out"
      }, "-=0.2");
      tlDesc.to(frameworksRef.current, {
        opacity: 0,
        scale: 0,
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        duration: 10,
        ease: "power3.out"
      }, "-=0.1");

      tlDesc.to(toolsRef.current, {
        opacity: 1,
        scale: 1,
        transform: "translate(-50%, -50%)",
        zIndex: 3,
        duration: 10,
        ease: "power3.out"
      }, "-=0.2");
      tlDesc.to(toolsRef.current, {
        opacity: 0,
        scale: 0,
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        duration: 10,
        ease: "power3.out"
      }, "-=0.1");

      tlDesc.to(cmsRef.current, {
        opacity: 1,
        scale: 1,
        transform: "translate(-50%, -50%)",
        zIndex: 3,
        duration: 10,
        ease: "power3.out"
      }, "-=0.2");

    tlDesc.to(codingRef.current, {
      opacity: 0,
      scale: 0,
      zIndex: 1,
      duration: 10,
      ease: "power3.out"
    }, "-=0.3", {scrub: 0.1});
    
    tlDesc.to(webDesignRef.current, {
      opacity: 1,
      scale: 1,
      zIndex: 2,
      duration: 10,
      ease: "power3.out"
    }, "-=0.3", {scrub: 0.1});
    tlDesc.to(webDesignDescRef.current, {
      opacity: 1,
      scale: 1,
      transform: "translate(-50%, -50%)",
      zIndex: 3,
      duration: 10,
      ease: "power3.out"
    }, "-=0.2");
    tlDesc.to(webDesignDescRef.current, {
      opacity: 0,
      scale: 0,
      transform: "translate(-50%, -50%)",
      zIndex: 1,
      duration: 10,
      ease: "power3.out"
    }, "-=0.1");
    tlDesc.to(webDesignSkillsRef.current, {
      opacity: 1,
      scale: 1,
      transform: "translate(-50%, -50%)",
      zIndex: 3,
      duration: 10,
      ease: "power3.out"
    }, "-=0.2");
    tlDesc.to(webDesignRef.current, {
      opacity: 0,
      scale: 0,
      zIndex: 1,
      duration: 10,
      ease: "power3.out"
    }, "-=0.1", {scrub: 0.1});

    tlDesc.to(workFlowRef.current, {
      opacity: 1,
      scale: 1,
      zIndex: 2,
      duration: 10,
      ease: "power3.out"
    }, "-=0.3", {scrub: 0.1});
    tlDesc.to(workFlowDescRef.current, {
      opacity: 1,
      scale: 1,
      transform: "translate(-50%, -50%)",
      zIndex: 3,
      duration: 10,
      ease: "power3.out"
    }, "-=0.2");
    tlDesc.to(workFlowDescRef.current, {
      opacity: 0,
      scale: 0,
      transform: "translate(-50%, -50%)",
      zIndex: 1,
      duration: 10,
      ease: "power3.out"
    }, "-=0.1");
    tlDesc.to(workFlowSkillsRef.current, {
      opacity: 1,
      scale: 1,
      transform: "translate(-50%, -50%)",
      zIndex: 3,
      duration: 10,
      ease: "power3.out"
    }, "-=0.1");
  
    // Cleanup animacji przy demontażu komponentu
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      tlDesc.kill();
    };
  }, []);

  useLayoutEffect(() => {
    ScrollTrigger.refresh();
    const myTrigger = document.querySelector('#wandachowicz');
    
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: myTrigger,
        start: "+=50px",
        end: () => "+=" + myTrigger.offsetHeight,
        scrub: 0.1,
      },
    });
    const totalRotations = 19;
    tl.to(competencesScrollIcon.current, {
      rotation: 360 * totalRotations, // rotacja 360 stopni razy ilość pełnych obrotów
      ease: "none", // bez ease, aby obrót był równomierny
    });
    return () => {
      tl.kill();
    };
  }, []);

  useEffect(() => {
    const scrollWheel = competencesScrollIcon.current;
    scrollWheel.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }, []);
  

  return (
    <>
      <Helmet>
        <title>{t('competenciesSinglePageSeoTitle')}</title>
        <meta name="description" content={t('competenciesSinglePageSeoDescription')} />
        <meta property="og:title" content={t('competenciesSinglePageSeoTitle')} />
        <meta property="og:description" content={t('competenciesSinglePageSeoDescription')} />
      </Helmet>
      <Header />
      <img src={"/img/icons/competenciesSectionBg.svg"} alt={"Scroll"} id="competencesScrollIcon" ref={competencesScrollIcon} width={120} height={92.7} />
      <section id="heroSection" ref={heroSectionRef}>
        <h1>
          <span className="clamp">&#123;</span>
          <span id="title"></span>
          <span className="clamp">&#125;</span>
        </h1>
      </section>
      <section id="competenciesPage" ref={competenciesRef}>
        <div id="coding" ref={codingRef} style={{ transform: "scale(0)", opacity: 0 }}>
          <div className="competenciesDesc" ref={codingDescRef} style={{ transform: "scale(5) translate(0, 0)", opacity: 0 }}>
            <h2>{t('competenciesPageCodingTitle')}</h2>
            <p>{t('competenciesPageCodingDesc')}</p>
          </div>
          <div className="skills" ref={languagesRef} style={{ transform: "scale(5)", opacity: 0 }}>
            <h3>{t('competenciesPageCodingSkillsLanguages')} <span>&#40;•••••&#41;</span></h3>
            <ul>
            {skills.coding.languages.map((language, index) => (
              <li className={`level-${language.level}`} key={index}>
                {language.name}
                <span>{renderDots(language.level)}</span>
              </li>
            ))}
            </ul>
          </div>
          <div className="skills" ref={frameworksRef} style={{ transform: "scale(5)", opacity: 0 }}>
            <h3>{t('competenciesPageCodingSkillsFrameworks')} <span>&#40;•••••&#41;</span></h3>
            <ul>
            {skills.coding.frameworks_and_libraries.map((fal, index) => (
              <li className={`level-${fal.level}`} key={index}>
                {fal.name}
                <span>{renderDots(fal.level)}</span>
              </li>
            ))}
            </ul>
          </div>
          <div className="skills" ref={toolsRef} style={{ transform: "scale(5)", opacity: 0 }}>
            <h3>{t('competenciesPageCodingSkillsTools')} <span>&#40;•••••&#41;</span></h3>
            <ul>
            {skills.coding.tools_and_technologies.map((tools, index) => (
              <li className={`level-${tools.level}`} key={index}>
                {tools.name}
                <span>{renderDots(tools.level)}</span>
              </li>
            ))}
            </ul>
          </div>
          <div className="skills" ref={cmsRef} style={{ transform: "scale(5)", opacity: 0 }}>
            <h3>{t('competenciesPageCodingSkillsCms')} <span>&#40;•••••&#41;</span></h3>
            <ul>
            {skills.coding.cms.map((cms, index) => (
              <li className={`level-${cms.level}`} key={index}>
                {cms.name}
                <span>{renderDots(cms.level)}</span>
              </li>
            ))}
            </ul>
          </div>
        </div>

        <div id="webDesign" ref={webDesignRef} style={{ transform: "scale(0)", opacity: 0 }}>
          <div className="competenciesDesc" ref={webDesignDescRef} style={{ transform: "scale(5)", opacity: 0 }}>
            <h2>{t('competenciesPageWebDesignTitle')}</h2>
            <p>{t('competenciesPageWebDesignDesc')}</p>
          </div>
          <div className="skills" ref={webDesignSkillsRef} style={{ transform: "scale(5)", opacity: 0 }}>
            <h3>{t('competenciesPageWebDesignSkills')} <span>&#40;•••••&#41;</span></h3>
            <ul>
            {skills.design.map((skill, index) => (
              <li className={`level-${skill.level}`} key={index}>
                {skill.name}
                <span>{renderDots(skill.level)}</span>
              </li>
            ))}
            </ul>
          </div>
        </div>
        
        <div id="workFlow" ref={workFlowRef} style={{ transform: "scale(0)", opacity: 0 }}>
          <div className="competenciesDesc" ref={workFlowDescRef} style={{ transform: "scale(5)", opacity: 0 }}>
            <h2>{t('competenciesPageWorkFlowTitle')}</h2>
            <p>{t('competenciesPageWorkFlowDesc')}</p>
          </div>
          <div className="skills" ref={workFlowSkillsRef} style={{ transform: "scale(5)", opacity: 0 }}>
            <h3>{t('competenciesPageWorkFlowSkills')} <span>&#40;•••••&#41;</span></h3>
            <ul>
            {skills.flow.map((skill, index) => (
              <li className={`level-${skill.level}`} key={index}>
                {skill.name}
                <span>{renderDots(skill.level)}</span>
              </li>
            ))}
            </ul>
          </div>
        </div>
      </section>
      <BottomHooks transalteHooksHeading='bottomHooksHeadingCompetencies' transalteHooksSpan='bottomHooksSpanCompetencies' />
    </>
  )
}